import React, { useEffect, useState } from 'react';
import PolizaSelector from './NewSiniestroComponents/PolizaSelector';
import ThirdDriving from './NewSiniestroComponents/ThirdDriving';
import ThirdInvolved from './NewSiniestroComponents/ThirdInvolved';
import TypeSelector from './NewSiniestroComponents/TypeSelector';
import RadioInputSelector from '../../../components/RadioInputSelector/RadioInputSelector';
import { useNavigate, useLocation } from 'react-router-dom';
import { roles, states } from '../../../constants';
import Spinner from '../../../components/Spinner/Spinner';
import styles from './ReportSiniestro.module.css';
import UserCard from '../../Admin/UsersList/UserCard';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getUserByDocument, usersSelector } from '../../../redux/users';
import './ReportSiniestro.module.css';
import { warningSideAlert } from '../../../redux/alerts';
import { createSiniestro } from '../../../redux/siniestros';

const NewSiniestro = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(usersSelector.userData);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userToAssign, setUserToAssign] = useState(false);
  const [userToSearch, setUserToSearch] = useState({
    documentType: '',
    documentNumber: '',
  });
  const [selectedPoliza, setSelectedPoliza] = useState(false);
  const [searching, setSearching] = useState(false);
  const [polizasBuscado, setPolizasBuscado] = useState([]);
  const [forceRender, setForceRender] = useState(false);

  const [thirdDriving, setThirdDriving] = useState(true);
  const [thirdInvolved, setThirdInvolved] = useState(false);
  const [denunciante, setDenunciante] = useState({
    userId: user._id,
    polizaId: '',
    floatVehicleDomain: '',
  });
  const [siniestro, setSiniestro] = useState({
    guilt: '',
    tipoSiniestro: '',
    date: '',
    direccion: '',
    entreCalles: '',
    state: '',
    localidad: '',
    detalle: '',
  });
  const [conductor, setConductor] = useState({
    socialReason: '',
    document: {
      documentType: '',
      documentNumber: '',
    },
  });
  const [tercero, setTercero] = useState({
    socialReason: '',
    document: {
      documentType: '',
      documentNumber: '',
    },
    insuranceCompany: '',
    polizaNumber: '',
    domain: '',
  });

  const location = useLocation();
  const userDetailFromRedirect = location.state;

  useEffect(() => {
    if (userDetailFromRedirect) {
      setUserToAssign(userDetailFromRedirect);
      setPolizasBuscado(userDetailFromRedirect.polizas);
      setDenunciante((denunciante) => ({
        ...denunciante,
        userId: userDetailFromRedirect._id,
      }));
      setSearching(true);
      setUserToSearch(userDetailFromRedirect.document);
    }
  }, [userDetailFromRedirect]);

  const handleOnSubmitUser = async (e) => {
    e.preventDefault();
    if (!userToSearch.documentNumber || !userToSearch.documentType)
      return dispatch(warningSideAlert('Por favor completa los campos antes de continuar'));

    setUserToAssign(false);
    setSearching(true);

    const res = await dispatch(getUserByDocument(userToSearch)).unwrap();
    if (!res) return setSearching(false);
    setUserToAssign(res);
    setPolizasBuscado(res.polizas);
    setDenunciante((denunciante) => ({
      ...denunciante,
      userId: res._id,
    }));
  };

  const handleOnChangeUser = (e) => {
    setSearching(false);
    setUserToSearch({
      ...userToSearch,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnChangeDenunciante = (e) => {
    if (e.target.name === 'polizaId') {
      const auxPolizaSelected = JSON.parse(e.target.value);
      setSelectedPoliza(auxPolizaSelected);
      return setDenunciante({
        ...denunciante,
        [e.target.name]: auxPolizaSelected._id,
      });
    }
    setDenunciante({ ...denunciante, [e.target.name]: e.target.value });
  };

  const handleOnChangeSiniestro = (e) => {
    setSiniestro({ ...siniestro, [e.target.name]: e.target.value });
  };

  const handleOnChangeConductor = (e) => {
    if (
      e.target.name === 'documentType' ||
      e.target.name === 'documentNumber'
    ) {
      return setConductor({
        ...conductor,
        document: {
          ...conductor.document,
          [e.target.name]: e.target.value,
        },
      });
    } else {
      return setConductor({ ...conductor, [e.target.name]: e.target.value });
    }
  };

  const handleOnChangeTercero = (e) => {
    if (
      e.target.name === 'documentType' ||
      e.target.name === 'documentNumber'
    ) {
      return setTercero({
        ...tercero,
        document: {
          ...tercero.document,
          [e.target.name]: e.target.value,
        },
      });
    } else {
      return setTercero({ ...tercero, [e.target.name]: e.target.value });
    }
  };

  const handleOnSubmitNewSiniestro = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    const data = { denunciante, siniestro, conductor, tercero };

    formData.append('type', 'siniestros');
    formData.append('data', JSON.stringify({ data }));

    const generalFilesInput = document.querySelector(
      'input[name="siniestroFiles"]',
    );
    if (generalFilesInput) {
      const generalFiles = generalFilesInput.files;
      for (let i = 0; i < generalFiles.length; i++) {
        formData.append('siniestroFiles', generalFiles[i]);
      }
    }

    const driverFilesInput = document.querySelector(
      'input[name="driverFiles"]',
    );
    if (driverFilesInput) {
      const driverFiles = driverFilesInput.files;
      for (let i = 0; i < driverFiles.length; i++) {
        formData.append('driverFiles', driverFiles[i]);
      }
    }

    const thirdFilesInput = document.querySelector('input[name="thirdFiles"]');
    if (thirdFilesInput) {
      const thirdFiles = thirdFilesInput.files;
      for (let i = 0; i < thirdFiles.length; i++) {
        formData.append('thirdFiles', thirdFiles[i]);
      }
    }

    const res = await dispatch(createSiniestro(formData)).unwrap();
    if (!res) setLoading(false);
    else navigate(`/siniestros/${res._id}`);
  };

  return (
    <>
      <h1>Reportar Siniestro</h1>
      <div className="contentContainer">
        {user.role === roles.supreme ? (
          <>
            <form onSubmit={handleOnSubmitUser} className="searchUserForm">
              <h5 className="card-title text-center mt-2">
                Buscá el cliente que le asignarás el siniestro
              </h5>
              <div className={styles.doubleInputWrapper} style={{ marginBottom: "1rem" }}>
                <div className={styles.labelAndInputWrapper}>
                  <label>
                    Tipo de documento <span className="requiredFormField">*</span>
                  </label>
                  <select
                    className="form-select mb-1"
                    onChange={handleOnChangeUser}
                    name="documentType"
                  >
                    <option>Tipo de documento</option>
                    <option
                      defaultValue={userToSearch.documentType === 'DNI'}
                      value="DNI"
                    >
                      DNI
                    </option>
                    <option
                      defaultValue={userToSearch.documentType === 'CUIT'}
                      value="CUIT"
                    >
                      CUIT
                    </option>
                  </select>
                </div>
                <div className={styles.labelAndInputWrapper}>
                  <label>
                    N° de Documento <span className="requiredFormField">*</span>
                  </label>
                  <input
                    className="form-control"
                    name="documentNumber"
                    placeholder="Ingresá acá el DNI o CUIT"
                    onChange={handleOnChangeUser}
                    value={userToSearch.documentNumber}
                    type="number"
                    required
                  />
                </div>
              </div>
              {searching ? (
                userToAssign ? (
                  <UserCard user={userToAssign} preview />
                ) : (
                  <Spinner />
                )
              ) : (
                <div className="searchUserButtonWrapper">
                  <button type="submit" className="btn btn-info mt-1 mb-2">
                    Buscar Usuario
                  </button>
                </div>
              )}
            </form>
          </>
        ) : (
          <div className={styles.reportForm}>
            <h5 className="text-center mb-0 mt-4">Datos del denunciante</h5>
            <div className={styles.doubleInputWrapper}>
              <div className={styles.labelAndInputWrapper}>
                <label>
                  Asegurado <span className="requiredFormField">*</span>
                </label>
                <input
                  className="form-control"
                  name="socialReason"
                  value={`${user.socialReason}`}
                  disabled={true}
                  type="text"
                  required
                />
              </div>
              <div className={styles.labelAndInputWrapper}>
                <label>
                  Email <span className="requiredFormField">*</span>
                </label>
                <input
                  className="form-control"
                  name="email"
                  value={`${user.email}`}
                  disabled={true}
                  type="text"
                  required
                />
              </div>
            </div>
          </div>
        )}
        <form
          onSubmit={handleOnSubmitNewSiniestro}
          className={styles.reportForm}
        >
          <div className={styles.doubleInputWrapper}>
            <div className={styles.labelAndInputWrapper}>
              {user.role === roles.client ||
                user.role === roles.clientEnterprise ||
                (user.role === roles.supreme && userToAssign) ? (
                <PolizaSelector
                  key={forceRender}
                  handleOnChangeDenunciante={handleOnChangeDenunciante}
                  polizas={userToAssign ? userToAssign.polizas : user.polizas}
                  polizasBuscado={polizasBuscado}
                />
              ) : (
                false
              )}
            </div>
            <div className={styles.labelAndInputWrapper}>
              <label>
                Seleccione el culpable del siniestro{' '}
                <span className="requiredFormField">*</span>
              </label>
              <select
                className="form-select"
                name="guilt"
                onChange={handleOnChangeSiniestro}
                required
                defaultValue=""
              >
                <option value="" disabled hidden>
                  Seleccione el culpable del siniestro
                </option>
                <option value="Mi culpa">Mi culpa</option>
                <option value="No fue mi culpa">No fué mi culpa</option>
                <option value="Auto estacionado">
                  El auto estaba estacionado (Sin conductor)
                </option>
              </select>
            </div>
          </div>
          {selectedPoliza && selectedPoliza.polizaType === 'Flota' && (
            <>
              <label className="mt-3">
                Vehículo involucrado de la flota{' '}
                <span className="requiredFormField">*</span>
              </label>
              <select
                className="form-select"
                name="floatVehicleDomain"
                onChange={handleOnChangeDenunciante}
                required
                defaultValue=""
              >
                <option value="" disabled hidden>
                  Seleccione el vehículo{' '}
                </option>
                {selectedPoliza.float
                  .sort((a, b) =>
                    a.domain.localeCompare(b.domain, 'es', { numeric: true }),
                  )
                  .map((vehicle) => (
                    <option key={vehicle.domain} value={vehicle.domain}>
                      {vehicle.domain}
                    </option>
                  ))}
              </select>
            </>
          )}
          <div className={styles.doubleInputWrapper}>
            <div className={styles.labelAndInputWrapper}>
              <TypeSelector handleOnChangeSiniestro={handleOnChangeSiniestro} />
            </div>
            <div className={styles.labelAndInputWrapper}>
              <label>
                Selecciona una fecha{' '}
                <span className="requiredFormField">*</span>
              </label>
              <input
                type="date"
                className="form-control"
                id="fecha"
                name="date"
                onChange={handleOnChangeSiniestro}
                max={new Date().toISOString().split('T')[0]}
                required
              />
            </div>
          </div>
          <div className={styles.doubleInputWrapper}>
            <div className={styles.labelAndInputWrapper}>
              <label>
                Calle y altura donde ocurrió el suceso{' '}
                <span className="requiredFormField">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="direccion"
                onChange={handleOnChangeSiniestro}
                required
              />
            </div>
            <div className={styles.labelAndInputWrapper}>
              <label>
                ¿Entre qué calles sucedió?{' '}
                <span className="requiredFormField">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="entreCalles"
                onChange={handleOnChangeSiniestro}
                required
              />
            </div>
          </div>
          <div className={styles.doubleInputWrapper}>
            <div className={styles.labelAndInputWrapper}>
              <label>
                ¿En qué provincia? <span className="requiredFormField">*</span>
              </label>
              <select
                onChange={handleOnChangeSiniestro}
                name="state"
                className="form-select"
              >
                <option value="">Selecciona una provincia</option>
                {states.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles.labelAndInputWrapper}>
              <label>
                ¿En qué localidad? <span className="requiredFormField">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="localidad"
                onChange={handleOnChangeSiniestro}
                required
              />
            </div>
          </div>
          <label>
            Detállanos lo ocurrido <span className="requiredFormField">*</span>
          </label>
          <textarea
            className="form-control"
            rows="3"
            name="detalle"
            onChange={handleOnChangeSiniestro}
            required
          ></textarea>
          <div className="form-text">Contános cómo sucedió el siniestro. </div>
          <label className="mt-3">Subí acá fotos/documentos</label>
          <input
            className="form-control"
            type="file"
            name="siniestroFiles"
            multiple
            required
          />
          <div className="form-text">
            Cargue los archivos del tercero: (Cedula del vehículo, Registro del
            conductor, DNI de conductor, fotos delvehículo de los daños y que se
            vea la patente, denuncia policial. Todos los documentos frente y
            dorso)
          </div>

          <RadioInputSelector
            title={`¿El conductor del vehículo es el asegurado?`}
            setState={setThirdDriving}
            state={thirdDriving}
            required
          />
          {thirdDriving ? (
            false
          ) : (
            <ThirdDriving
              name={thirdDriving}
              handleOnChangeConductor={handleOnChangeConductor}
            />
          )}
          <RadioInputSelector
            title={`¿Hay terceros involucrados?`}
            setState={setThirdInvolved}
            state={thirdInvolved}
            required
          />
          {thirdInvolved ? (
            <ThirdInvolved
              name={thirdInvolved}
              handleOnChangeTercero={handleOnChangeTercero}
            />
          ) : (
            false
          )}
          <div className={styles.submitButtonWrapper}>
            <button
              type="submit"
              className="btn btn-warning mt-4"
              disabled={loading}
            >
              {loading ? (
                <div className="spinner-border text-light" role="status"></div>
              ) : (
                'Denunciar siniestro'
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default NewSiniestro;
